input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.comment-popover {
  z-index: 9999 !important;
}

.att-input {
  position: relative;
  display: inline-block;
}

.att-input .badge {
  position: absolute;
  top: -3px;
  right: -3px;
  border-radius: 50%;
  background: red;
  color: white;
  width: 1rem;
  height: 1rem;
  padding: 0px;
  margin: 0px;
}
